<template>
  <div id="BannerDetail" class="section">
    <div class="columns is-multiline is-vcentered">
      <!-- Back Button -->
      <div class="column is-1">
        <div @click.prevent="navigateToBannerManage">
          <b-icon class="clickable-icon" icon="chevron-left" size="is-medium" />
        </div>
      </div>
      <!-- Title -->
      <div class="column is-7-desktop is-7-tablet is-12-mobile">
        <h1 class="title">BANNER DETAILS</h1>
      </div>
      <!-- Create Button -->
      <div
        class="column is-2 is-hidden-mobile"
        :class="bannerObject ? '' : 'is-offset-2'"
      >
        <b-button
          expanded
          type="is-primary"
          icon-left="upload"
          :disabled="disableCreate"
          @click.prevent="uploadNewBannerButtonPressed"
        >
          {{ bannerObject ? "Update" : "Create" }}
        </b-button>
      </div>
      <!-- Remove Button -->
      <div v-if="bannerObject" class="column is-2 is-hidden-mobile">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveBanner"
        >
          Remove
        </b-button>
      </div>
    </div>

    <div class="container">
      <div style="margin-top: 1.5rem;">
        <div class="columns">
          <div class="column has-text-centered">
            <!-- Image Upload -->
            <b-field label="Banner Image" />
            <image-file-upload
              :mainImageUrl="mainImage"
              @uploaded_file="val => (mainImage = val)"
            />
            <p style="padding-top: 1rem; color: #676767;">
              Aspect Ratio 5:2 ( recommended 2000x800px ) max size: 2048px
            </p>
          </div>
          <div class="column">
            <!-- Title + Subtitle + Type -->
            <b-tabs type="is-boxed">
              <b-tab-item label="English">
                <b-field label="Title">
                  <b-input v-model="en_title" />
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="en_subtitle" />
                </b-field>
              </b-tab-item>
              <b-tab-item label="Chinese">
                <b-field label="Title">
                  <b-input v-model="zh_title" />
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="zh_subtitle" />
                </b-field>
              </b-tab-item>
              <b-tab-item label="Thai">
                <b-field label="Title">
                  <b-input v-model="th_title" />
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="th_subtitle" />
                </b-field>
              </b-tab-item>
            </b-tabs>

            <b-field label="Banner Type" />
            <b-field>
              <b-radio-button v-model="typeOption" native-value="cover">
                <b-icon icon="image-area" size="is-small" />
                <span>Cover</span>
              </b-radio-button>

              <b-radio-button v-model="typeOption" native-value="product">
                <b-icon icon="package" size="is-small" />
                <span>Product</span>
              </b-radio-button>
              <b-radio-button v-model="typeOption" native-value="news">
                <b-icon icon="newspaper-variant" size="is-small" />
                News
              </b-radio-button>
            </b-field>
            <!-- Product + News List -->
            <div v-if="typeOption && typeOption === 'product'">
              <selected-product-table
                :productId="selectedProduct"
                @product_update="setSelectedProduct"
              />
            </div>
            <div v-else-if="typeOption && typeOption === 'news'">
              <selected-news-table
                :newsId="selectedNews"
                @news_update="setSelectedNews"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile : Create Button -->
      <div class="is-hidden-tablet" style="margin-top: 1.5rem;">
        <b-button
          expanded
          type="is-primary"
          @click.prevent="uploadNewBannerButtonPressed"
        >
          Create
        </b-button>
      </div>
      <!-- Mobile: Remove Button -->
      <div
        v-if="bannerObject"
        class="is-hidden-tablet"
        style="margin-top: 1rem;"
      >
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveBanner"
        >
          Remove
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BannerApi from "@/apis/BannerApi";
import AlertManager from "@/utils/AlertManager";
import ImageFileUpload from "@/components/forms/ImageFileUpload";
import SelectedNewsTable from "@/components/tables/SelectedNewsTable";
import SelectedProductTable from "@/components/tables/SelectedProductTable";

export default {
  name: "BannerDetail",
  components: {
    ImageFileUpload,
    SelectedNewsTable,
    SelectedProductTable
  },
  props: ["banner"],
  data() {
    return {
      mainImage: null,
      en_title: null,
      en_subtitle: null,
      zh_title: null,
      zh_subtitle: null,
      th_title: null,
      th_subtitle: null,
      typeOption: null,
      selectedProduct: null,
      selectedNews: null,
      isEnableRemove: false,
      bannerObject: this.banner
    };
  },
  computed: {
    disableCreate: function() {
      if (
        (this.mainImage &&
          this.en_title &&
          this.en_subtitle &&
          this.zh_title &&
          this.zh_subtitle &&
          this.th_title &&
          this.th_subtitle &&
          this.typeOption) ||
        this.bannerObject
      ) {
        switch (this.typeOption) {
          case "news":
            return this.selectedNews ? false : true;
          case "product":
            return this.selectedProduct ? false : true;
          default:
            return false;
        }
      } else {
        return true;
      }
    }
  },
  methods: {
    setSelectedProduct: function(value) {
      this.selectedProduct = value;
    },
    setSelectedNews: function(value) {
      this.selectedNews = value;
    },
    uploadNewBannerButtonPressed: async function() {
      let params = {
        cover_image: this.mainImage,
        title: {
          en: this.en_title,
          th: this.th_title,
          zh: this.zh_title
        },
        sub_title: {
          en: this.en_subtitle,
          th: this.th_subtitle,
          zh: this.zh_subtitle
        },
        type: this.typeOption
      };
      switch (this.typeOption) {
        case "news":
          params.item_id = this.selectedNews._id;
          break;
        case "product":
          params.item_id = this.selectedProduct._id;
          break;
        default:
          break;
      }

      // Conditional update if _id is exist, then will create instead
      if (this.bannerObject) {
        this.updateExistingBanner(this.bannerObject._id, params);
      } else {
        this.createNewBanner(params);
      }
    },
    createNewBanner: async function(bannerInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await BannerApi.requestCreateBanner(bannerInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar("Banner was scucessfully created.");
      this.bannerObject = response;
    },
    updateExistingBanner: async function(banner_id, bannerInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await BannerApi.requestUpdateBanner(banner_id, bannerInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar("Banner was scucessfully updated.");
    },
    presentConfirmRemoveBanner: function() {
      if (this.bannerObject._id) {
        this.$buefy.dialog.confirm({
          title: "Confirm Remove Banner",
          message:
            "Are you sure you want to permanently remove <b>" +
            this.bannerObject.title.en +
            "</b>?",
          confirmText: "Confirm",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.removeBanner(this.bannerObject._id)
        });
      }
    },
    removeBanner: async function(banner_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await BannerApi.removeBannerById(banner_id);
      loadingComponent.close();
      if (response) {
        this.navigateToBannerManage();
      }
    },
    navigateToBannerManage: function() {
      this.$router.push({ name: "banner" });
    }
  },
  mounted() {
    if (this.bannerObject) {
      this.mainImage = this.bannerObject.cover_image;
      this.en_title = this.bannerObject.title.en;
      this.en_subtitle = this.bannerObject.sub_title.en;
      this.zh_title = this.bannerObject.title.zh;
      this.zh_subtitle = this.bannerObject.sub_title.zh;
      this.th_title = this.bannerObject.title.th;
      this.th_subtitle = this.bannerObject.sub_title.th;

      switch (this.bannerObject.type) {
        case "product":
          this.selectedProduct = this.bannerObject.item_id;
          break;

        case "news":
          this.selectedNews = this.bannerObject.item_id;
          break;

        default:
          break;
      }
      this.typeOption = this.bannerObject.type;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#BannerDetail {
  text-align: left;
}
.clickable-icon {
  cursor: pointer;
}
</style>
