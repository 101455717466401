<template>
  <div id="SelectedNewsTable">
    <b-table
      v-if="news"
      striped
      hoverable
      :data="news"
      :selected.sync="selected"
    >
      <template slot-scope="props">
        <b-table-column field="image" label="Image">
          <figure class="image is-128x128">
            <img class="cover-center" :src="props.row.cover_image" />
          </figure>
        </b-table-column>

        <b-table-column field="title" label="News Title">
          {{ props.row.header.en }}
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import NewsApi from "@/apis/NewsApi";

export default {
  name: "SelectedNewsTable",
  props: ["newsId"],
  data() {
    return {
      news: null,
      selected: null
    };
  },
  watch: {
    selected: function(val) {
      if (val) {
        this.$emit("news_update", val);
      }
    }
  },
  methods: {
    getNewsList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApi.requestNewsList();
      loadingComponent.close();
      if (response && response.length > 0) {
        this.news = response;
        if (this.newsId) {
          this.selected = this.news.find(item => item._id === this.newsId);
        }
      }
    }
  },
  mounted() {
    this.getNewsList();
  }
};
</script>

<style scoped>
.cover-center {
  height: 128px;
  object-position: center;
  object-fit: cover;
}
</style>
